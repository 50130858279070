<template>
  <div
    v-for="node in nodes"
    :key="node.key"
    class="ExtendedWysiwyg"
    :class="{
      'rich-text-block-container': node.type === 'blok',
      'rich-text-p-container': node.type === 'html' && node.content.match(/^<p/),
      'rich-text-content-container': node.type === 'html' && !node.content.match(/^<p/),
    }"
  >
    <div v-if="node.type === 'blok' && node.content">
      <SpaceHandler
        v-for="nodeItem in node.content.blok.content"
        :key="nodeItem._uid"
        :blok="nodeItem"
      >
        <div
          :class="[
            `ExtendedWysiwyg-${nodeItem.component}`,
            { 'full-width': nodeItem.component !== 'Button' }]"
        >
          <component :is="$resolveStoryBlokComponent(nodeItem)" :blok="nodeItem" />
        </div>
      </SpaceHandler>
    </div>
    <div v-else v-html="fixRelativeLinks(node.content)" />
  </div>
</template>

<script setup lang="ts">
import { ISbRichtext } from 'storyblok-js-client';
import SpaceHandler from '~/components/body/SpaceHandler.vue';

const props = defineProps<{
  content: ISbRichtext,
}>();

const nodes = computed(() => {
  return Object.entries(props.content.content).map(([key, node]: [key: string, node: any]) => {
    if (node.type === 'blok') {
      const blok = {
        content: node.attrs?.body,
      };

      return {
        key,
        type: 'blok',
        content: {
          blok,
        },
      };
    } else {
      return {
        key,
        type: 'html',
        content: renderRichText({
          type: 'doc',
          content: [
            node,
          ],
        } as ISbRichtext),
      };
    }
  });
});

const fixRelativeLinks = (input: string) => {
  return input.replace(/href="(?!http|\/|mailto)/g, 'href="/');
};

</script>

<style lang="postcss">
.full-width {
  @screen desk {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.wysiwyg .layout-container {
  padding-left: 0;
  padding-right: 0;
}
</style>
